import cogVideo from '@/assets/animations/cog.webm'
import whiplashVideo from '@/assets/animations/whiplash.webm'
import bitbVideo from '@/assets/animations/bitb.webm'
import ravenVideo from '@/assets/animations/raven.webm'
import pepeVideo from '@/assets/animations/pepe.webm'
import loungeVideo from '@/assets/animations/lounge.webm'
import thoraxVideo from '@/assets/animations/thorax.webm'
import lizcoinVideo from '@/assets/animations/lizcoin.webm'
import georgeVideo from '@/assets/animations/george.webm'

interface AnimationType {
    id: string;
    resolutions: {
        source: string;
        bounds: [number, number];
        width: number;
    }[]
}

export const animations: AnimationType[] = [
    {
        id: "cog",
        resolutions: [
            {
                source: cogVideo,
                bounds: [0, 10000],
                width: 2265
            }
        ]
    },
    {
        id: "whiplash",
        resolutions: [
            {
                source: whiplashVideo,
                bounds: [0, 10000],
                width: 1300
            }
        ]
    },
    {
        id: "bitb",
        resolutions: [
            {
                source: bitbVideo,
                bounds: [0, 10000],
                width: 2179
            }
        ]
    },
    {
        id: "raven",
        resolutions: [
            {
                source: ravenVideo,
                bounds: [0, 10000],
                width: 1979
            }
        ]
    },
    {
        id: "pepe",
        resolutions: [
            {
                source: pepeVideo,
                bounds: [0, 10000],
                width: 2446
            }
        ]
    },
    {
        id: "lounge",
        resolutions: [
            {
                source: loungeVideo,
                bounds: [0, 10000],
                width: 2560
            }
        ]
    },
    {
        id: "thorax",
        resolutions: [
            {
                source: thoraxVideo,
                bounds: [0, 10000],
                width: 2000
            }
        ]
    },
    {
        id: "lizcoin",
        resolutions: [
            {
                source: lizcoinVideo,
                bounds: [0, 10000],
                width: 1541
            }
        ]
    },
    {
        id: "george",
        resolutions: [
            {
                source: georgeVideo,
                bounds: [0, 10000],
                width: 1890
            }
        ]
    },
]