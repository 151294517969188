
import { SectionText, SectionTitle } from '@/components/ui/text';

import smokeImg from '@/assets/images/smoke.webp'
import smokeTopImg from '@/assets/images/smoke-top.webp'
import smokeBottomImg from '@/assets/images/smoke-bottom.webp'
import rocketImg from '@/assets/images/rocket.webp'
import rocketImgMobile from '@/assets/images/heroes/mobile/rocket.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { cn } from '@/lib/utils';
import { useSections } from '@/components/section-provider';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import thoraxVideo from '@/assets/animations/thorax.webm'
import { VideoAnimation } from '@/components/animations/video';

export const LizardLaunchpad = () => {

    const { section } = useSections();

    const ref = useRef(null);

    const isInView = section === 6;
    
    return (
        <motion.div ref={ref} key="lizlaunchpad" className={cn('absolute inset-0 h-full w-full', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>         
            <motion.img 
                src={smokeBottomImg} 
                className='absolute bottom-0 w-full z-0 flex md:hidden' 
                {...slideProps("left", isInView)}
            />
            <BrowserView>
                <motion.img 
                    src={smokeTopImg} 
                    className='absolute inset-0 w-full z-0 flex md:hidden' 
                    {...slideProps("left", isInView)}
                />
                <motion.img 
                    src={smokeImg} 
                    className='absolute inset-0 h-full w-full z-0 hidden md:flex' 
                    {...slideProps("left", isInView)}
                />
                
                {/* <motion.img
                    src={rocketImg}
                    className='absolute right-0 bottom-0 lg:bottom-[unset] h-1/2 md:top-[10%] md:h-[80%]'
                    {...slideProps("right",isInView)}
                /> */}
                <VideoAnimation
                    sectionIndex={6}
                    isInView={isInView}
                    videoId='thorax'
                    className='absolute right-0 bottom-0 lg:bottom-[unset] md:top-[18%] md:h-[70%]'
                    {...slideProps("right",isInView)}
                />
            </BrowserView>
            <MobileView>
                <motion.img
                    src={rocketImgMobile}
                    className='absolute bottom-2 right-0 mx-auto z-0 w-3/4'
                    {...slideProps("right", isInView)}
                />
            </MobileView>
            <div className='relative mx-auto h-[60%] md:h-full max-w-[90%] lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='flex flex-col col-span-full md:col-span-3 gap-3 md:gap-4 w-full text-left items-start'>
                    <div className='flex flex-col gap-3 md:gap-4 justify-center'>
                        <SectionTitle isInView={isInView}>Lizard Launchpad</SectionTitle>
                        <SectionText isInView={isInView} className='lg:max-w-xl lg:text-base'>
                            Are you a degen that dreams of being a VC? Blast off with the Lizard Launchpad and get access to the best early stage and public gaming token deals.
                        </SectionText>
                    </div>
                    <motion.a 
                        target='_blank'
                        href="https://launchpad.lizlabs.io"
                        className={cn(buttonVariants(), 'h-14 font-bold px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                    >
                        Access Token Deals
                    </motion.a>
                </div>
            </div>
        </motion.div>
    );
};