import { Link } from 'react-router-dom';
import { Button, buttonVariants } from '@/components/ui/button';

import logo from '@/assets/images/logo.png';
import logoDark from '@/assets/images/logo-dark.svg';
import logoIcon from '@/assets/images/logo-icon.svg';
import logoIconDark from '@/assets/images/logo-icon-dark.svg';
import cobalt from '@/assets/images/cobalt.png';
import { useCallback, useEffect, useRef } from 'react';
import { useSections } from '@/components/section-provider';
import { cn } from '@/lib/utils';
import { Menu, X } from 'lucide-react';
import { motion, useCycle } from 'framer-motion';
import { useDimensions } from '@/hooks/use-dimensions';
import { Navigation } from '@/components/navigation/menu';
import { SocialIcons } from '@/components/footer';
import { ScrollArea } from '@/components/ui/scroll-area';

const sidebar = {
    open: (height = 1000) => ({
        zIndex: 50,
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2,
            duration: 0.5,
            damping: 40
        }
    }),
    closed: {
        zIndex: -10,
        opacity: 0,
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};

export const LizCoinCTA = () => {
    return <div className='flex gap-12'>
        <div className="relative flex items-center gap-4 bg-cta-bg rounded-full p-4 pl-3 lg:pl-6 lg:p-2 px-3 md:pr-6 bg-opacity-30">
            <div className='w-12 lg:w-16 opacity-100'>
                <img src={cobalt} className='w-16 h-auto lg:w-14 lg:h-14' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />
            </div>
            <Link id="cta" target='_blank' to="https://lizlounge.io/" className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>$3,000,000 Awaits</span>
            </Link>
            {/* <Button id="cta" disabled className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Coming Soon</span>
            </Button> */}
        </div>
    </div>
}

export const Header = () => {

    const { moveSection } = useSections();

    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);

    useEffect(() => {
        const animateInterval = setInterval(() => {
            document.getElementById("cta")?.classList.add("bg-cta-active")
            setTimeout(() => {
                document.getElementById("cta")?.classList.remove("bg-cta-active")
            }, 600)
        }, 7000);

        return () => clearInterval(animateInterval);
    }, [])

    const sectionPress = useCallback((x: number) => {
        toggleOpen(1);
        moveSection(0, x);
    }, [isOpen, toggleOpen]);

    return (
        <>
        <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
            className='relative'
        >
            <motion.div
                className="fixed left-0 top-0 right-0 bottom-0 w-full h-full bg-[#1F1D40] bg-opacity-[0.98] flex flex-col items-start justify-center px-[40px] lg:px-32" 
                variants={sidebar} 
                transition={{ duration: 1 }}
                onClick={() => toggleOpen()}
            >
                <ScrollArea className='pr-8 py-4'>
                    <Navigation sectionPress={sectionPress} />
                    <SocialIcons className="pt-10 pl-10" />
                </ScrollArea>
            </motion.div>
            <motion.button
                type="button"
                className='fixed bg-white top-8 right-8 md:top-20 md:right-20 z-50 rounded-full flex justify-center items-center w-10 h-10 md:w-[60px] md:h-[60px]'
                initial={false}
                animate={isOpen ? {
                    opacity: 1
                } : {
                    opacity: 0
                }}
                transition={{ duration: 1 }}
                onClick={() => toggleOpen()}
            >
                <X color='#1F1D40' className='w-6 h-6 md:w-10 md:h-10' />
            </motion.button>
        </motion.nav>
        <header className="fixed top-0 flex justify-between items-center gap-4 px-4 pt-2 md:pt-6 md:pr-16 w-full lg:pl-40 z-[12]">      
            <div className='flex items-center gap-x-4'>
                <button
                    className='flex flex-col'
                    onClick={() => toggleOpen()}
                >
                    <Menu className='text-foreground' />
                </button>
                <Button
                    onClick={() => moveSection(0, 0)}
                    className="hidden md:flex items-center"
                    variant="ghost"
                >
                    <img
                        src={logo}
                        className="max-w-[220px] h-[34px] logo !pointer-events-none"
                    />
                    <img
                        src={logoDark}
                        className="max-w-[220px] h-[34px] dark-logo"
                    />
                    <span className="sr-only">Lizard Labs</span>
                </Button>
                <Button
                    onClick={() => moveSection(0, 0)}
                    className="flex md:hidden items-center"
                    variant="ghost"
                >
                    <img
                        src={logoIcon}
                        className="w-6 h-6 logo !pointer-events-none"
                    />
                    <img
                        src={logoIconDark}
                        className="w-6 h-6 dark-logo"
                    />
                    <span className="sr-only">Lizard Labs</span>
                </Button>
            </div>
            <LizCoinCTA />
        </header>
        </>
    );
};