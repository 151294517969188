import { SectionText, SectionTitle } from '@/components/ui/text';
import guardianImgMobile from '@/assets/images/heroes/mobile/tiny-cog.webp'
import guardianVideo from '@/assets/animations/cog.webm'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { slideProps } from '@/components/animations/utils';
import { useRef } from 'react';
import { useSections } from '@/components/section-provider';
import { BrowserView, MobileView } from 'react-device-detect';
import { VideoAnimation } from '@/components/animations/video';

export const GuardiansOfGaming = () => {

    const { section } = useSections();

    const ref = useRef(null);

    const isInView = section === 0;

    return (
        <motion.div ref={ref} key="gog" className={cn('absolute inset-0 h-full w-full', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <BrowserView>
                <VideoAnimation
                    sectionIndex={0}
                    className='absolute right-0 bottom-0 lg:h-full z-0' 
                    isInView={isInView}
                    videoId='cog'
                />
            </BrowserView>
            <MobileView>
                <motion.img 
                    src={guardianImgMobile}
                    className='absolute w-full -bottom-2 z-0' 
                    {...slideProps("right", isInView)}
                />
            </MobileView>
            <div className='relative mx-auto h-2/3 md:h-full px-2 lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='col-span-full md:col-span-3 gap-4 w-full items-center px-2 lg:px-0 text-left md:items-start'>
                    <div className='flex flex-col gap-2 md:gap-4 justify-center w-full'>
                        <SectionTitle isInView={isInView}>Scale Up Your Game</SectionTitle>
                        <SectionText isInView={isInView} className='text-foreground font-nekstbold text-xl lg:text-3xl 3xl:text-[32px]'>Attain Guardian of Gaming Status</SectionText>
                        <SectionText isInView={isInView} className='md:max-w-lg py-5 !leading-6 max-w-[304px]'>
                            Complete Cold-Blooded Bounties to fill your Vault with Lizcoin and exclusive rewards!
                        </SectionText>
                    </div>
                    <motion.a 
                        target='_blank'
                        href="https://lizlounge.io/"
                        className={cn(buttonVariants(), 'font-bold mt-2 px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                    >
                        Become a Guardian of Gaming
                    </motion.a>
                </div>
            </div>
        </motion.div>
    );
};