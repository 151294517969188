import gridImg from '@/assets/images/grid-2.png'
import playerImg from '@/assets/images/heroes/2.webp'
import { motion } from 'framer-motion';
import { slideProps, transition } from '@/components/animations/utils';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { useSections } from '@/components/section-provider';
import { useRef } from 'react';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { BrowserView, MobileView } from 'react-device-detect';
import whiplashVideo from '@/assets/animations/whiplash.webm'
import { VideoAnimation } from '@/components/animations/video';

export const ReadyPlayerFun = () => {

    const { section } = useSections();

    const ref = useRef(null);

    const isInView = section === 1;

    return (
        <motion.div ref={ref} key="rpf" className={cn('absolute inset-0 h-full w-full', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <motion.img 
                src={gridImg} 
                className='opacity-50 absolute left-0 bottom-0 lg:h-[35%] z-0 scale-125' 
                {...slideProps("left", isInView)}
            />
            <div className='relative mx-auto h-full max-w-[90%] lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='flex flex-col col-span-full md:col-span-3 gap-6 md:gap-4 w-full text-left items-start z-[1]'>
                    <div className='flex flex-col gap-6 lg:gap-4 justify-center w-full'>
                        <SectionTitle isInView={isInView}>Ready Player Fun</SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[320px] md:max-w-lg lg:py-5 !leading-6'>
                            The LizDex chronicles the history of our universe. Venture into new strange dimensions, challenges and victories that pull you deeper into our saga.
                        </SectionText>
                    </div>
                    <motion.a 
                        target="_blank"
                        href="https://lizdex.lizlabs.io"
                        className={cn(buttonVariants(), 'h-14 font-bold px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                    >
                        Enter the LizDex
                    </motion.a>
                </div>

                <div className='col-span-full md:col-span-2 hidden lg:flex justify-end w-2/5 lg:w-full'>
                    <BrowserView>
                        <VideoAnimation
                            sectionIndex={1}
                            className='mt-16'
                            isInView={isInView}
                            videoId='whiplash'
                            initial={{ opacity: 0, x: 300, scale: 0.95 }}
                            animate={isInView ? "show" : "hide"}
                            variants={{
                                show: {
                                opacity: 1, x: 0, scale: 0.95
                                },
                                hide: {
                                    opacity: 0, x: 300, scale: 0.95
                                }
                            }}
                            transition={transition}
                        />
                    </BrowserView>
                    {/* <motion.img
                        initial={{ opacity: 0, x: 300, scale: 1.2 }}
                        animate={isInView ? "show" : "hide"}
                        variants={{
                            show: {
                            opacity: 1, x: 0, scale: 1.2
                            },
                            hide: {
                                opacity: 0, x: 300, scale: 1.2
                            }
                        }}
                        transition={transition}
                        src={playerImg}
                    /> */}
                </div>

                <MobileView>
                    <div className='absolute -bottom-2 -right-12 w-full'>
                        <motion.img
                            initial={{ opacity: 0, x: 300 }}
                            animate={isInView ? "show" : "hide"}
                            variants={{
                                show: {
                                opacity: 1, x: 0
                                },
                                hide: {
                                    opacity: 0, x: 300
                                }
                            }}
                            transition={transition}
                            src={playerImg}
                        />
                    </div>
                </MobileView>
            </div>
        </motion.div>
    );
};